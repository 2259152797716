import './App.scss';
import {ChakraProvider} from "@chakra-ui/react"
import {Stepper} from './comnponents/Stepper';
import {IntlProvider} from "react-intl";

import German from './lang/de.json';
import French from './lang/fr.json';
import Dutch from './lang/nl.json';
import English from './lang/en.json';
import Polish from './lang/pl.json';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/nl';
import 'moment/locale/pl';

const currentUrl = window.location.toString();

let messages = German;
let locale = 'de';
let momentLocale = 'de';

switch (true) {
    case (currentUrl.search('/fr') !== -1):
        messages = French;
        locale = 'fr';
        momentLocale = 'fr';
        break;

    case (currentUrl.search('/nl') !== -1):
        messages = Dutch;
        locale = 'nl';
        momentLocale = 'nl';
        break;

    case (currentUrl.search('/uk') !== -1):
        messages = English;
        locale = 'en';
        momentLocale = 'en-gb';
        break;

    case (currentUrl.search('/pl') !== -1):
        messages = Polish;
        locale = 'pl';
        momentLocale = 'pl';
        break;

    case (currentUrl.search('/ie') !== -1):
        messages = English;
        messages.outgoingUrl = 'https://c-plusextra.com/ie/order-now.html';
        locale = 'en';
        momentLocale = 'en-gb';
        break;

    case (currentUrl.search('/benl') !== -1):
        messages = Dutch;
        messages.outgoingUrl = 'https://cplusextra.com/benl/bestel.html';
        locale = 'nl';
        momentLocale = 'nl';
        break;

    case (currentUrl.search('/befr') !== -1):
        messages = French;
        messages.outgoingUrl = 'https://cplusextra.com/befr/commander.html';
        locale = 'fr';
        momentLocale = 'fr';
        break;

    case (currentUrl.search('/at') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://cplusextra.com/at/jetzt-kaufen.html';
        locale = 'de';
        break;

    case (currentUrl.search('/ch') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://c-plusextra.com/ch/jetzt-kaufen.html';
        locale = 'de';
        break;
}

moment.locale(momentLocale);

document.title = 'C+ ' + messages.title;

function App() {
    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;

import React from 'react';
import {Box, Grid, GridItem, Container, Text} from '@chakra-ui/react';
import {PageHeader} from './PageHeader';
import {CheckCircleIcon} from '@chakra-ui/icons';
import {FormattedMessage} from "react-intl";

export const PageLayout = ({children}) => {
    return (
        <Box>
            <PageHeader/>
            <Container className="main-contianer" maxW="container.lg">
                <Grid mb={20} templateColumns="repeat(2, 1fr)">
                    <GridItem rowSpan={2} colSpan={1} pt={[1, 7]}>
                        <Box className="text-icon-conatiner">
                            <Box className="icon-text" mb="6">
                                <Box className="icon">
                                    <CheckCircleIcon w={8} h={8}/>
                                </Box>
                                <Box>
                                    <Text><FormattedMessage id={'start.fact1'}/></Text>
                                </Box>
                            </Box>
                            <Box className="icon-text" mb="6">
                                <Box className="icon">
                                    <CheckCircleIcon w={8} h={8}/>
                                </Box>
                                <Box>
                                    <Text><FormattedMessage id={'start.fact2'}/></Text>
                                </Box>
                            </Box>
                            <Box className="icon-text" mb="6">
                                <Box className="icon">
                                    <CheckCircleIcon w={8} h={8}/>
                                </Box>
                                <Box>
                                    <Text><FormattedMessage id={'start.fact3'}/></Text>
                                </Box>
                            </Box>
                        </Box>
                    </GridItem>
                    <GridItem className="form-wrapper" rowSpan={2}>
                        <Box className="form-container" mt={-40} boxShadow="xl" mx="10" p="8" rounded="md">
                            {children}
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );
};

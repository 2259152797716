import React, { Suspense } from 'react'
import { Formiz, FormizStep, useForm } from '@formiz/core';
import { Box, Fade, Grid, Stack } from '@chakra-ui/react';
import { PageLayout } from '../layout/PageLayout';

// Step Buttons
import { NextButton } from './buttons/NextButton';
import { PreviousButton } from './buttons/PreviousButton';
import { DotsStepper } from './utils/DotStepper';

import moment from 'moment';

// Steps
const UserGoals = React.lazy(() => import('./steps/Step2'));
const UserDetails = React.lazy(() => import('./steps/UserDetails'));
const Complete = React.lazy(() => import('./steps/Complete'));
const Step3 = React.lazy(() => import('./steps/Step3'));
const Step4 = React.lazy(() => import('./steps/Step4'));

const StepperButtons = () => (
    <Stack>
        <Grid templateColumns="1fr 1fr">
            <Box>
                <PreviousButton />
            </Box>
            <Box textAlign="right">
                <NextButton />
            </Box>
        </Grid>
    </Stack>
);

export const Stepper = () => {
    const form = useForm({ subscribe: 'form' });

    let endDate = moment().add(90, 'days');
    let startDate = moment(endDate).subtract(7, 'days').format('Do MMMM YYYY');

    let estimatedEndDate = endDate.format('Do MMMM YYYY');
    let estimatedEndDays = 90;

    let todayDate = moment().format('Do MMMM YYYY');

    let graphPercentValue = Math.floor(Math.random() * (65 - 40)) + 40;

    const handleSubmit = (values) => {
        window.scrollTo(0, 0);
    };

    return (
        <Formiz connect={form} onValidSubmit={handleSubmit}>
            {form.isSubmitted ?
                <Suspense fallback={<></>}>
                    <Fade in={true} delay={0.1}>
                        <Box>
                            <Complete
                                todayDate={todayDate}
                                startDate={startDate}
                                estimatedEndDate={estimatedEndDate}
                                daysToEstimatedEndDate={estimatedEndDays}
                                graphPercentValue={graphPercentValue}
                            />
                        </Box>
                    </Fade>
                </Suspense>
                :
                <PageLayout>
                    <form noValidate onSubmit={form.submitStep}>
                        <Box className="stepDots-container" p="4" borderRadius="md" mb="6">
                            <DotsStepper />
                        </Box>
                        <FormizStep name="step1">
                            <Suspense fallback={<></>}>
                                <UserDetails />
                            </Suspense>
                        </FormizStep>

                        <FormizStep name="step2">
                            <Suspense fallback={<></>}>
                                <UserGoals />
                            </Suspense>
                        </FormizStep>

                        <FormizStep name="step3">
                            <Suspense fallback={<></>}>
                                <Step3 />
                            </Suspense>
                        </FormizStep>

                        <FormizStep name="step4">
                            <Suspense fallback={<></>}>
                                <Step4 />
                            </Suspense>
                        </FormizStep>

                        <Stack spacing="6" mt="5">
                            <StepperButtons title="Dots stepper" />
                        </Stack>
                    </form>
                </PageLayout>
            }
        </Formiz>

    )
}

import React from 'react';
import {Box, Badge, Container, Heading, Text, HStack, Button, Show} from '@chakra-ui/react';
import logo from '../assets/img/logo.svg';
import {ArrowForwardIcon} from "@chakra-ui/icons";
import {FormattedMessage} from "react-intl";

export const PageHeader = () => {
    const scrollToBottom = () => {
        window.scroll({
            top: document.body.offsetHeight, left: 0, behavior: 'smooth',
        });
    };

    return (<Box className="header-container" w="100%" pt={[5, 70]} pb={[2, 70]}>
        <Container maxW="container.lg">
            <HStack flexDirection={{base: 'column', md: 'row'}}>
                <Box w={{sm: '100%', md: '50%'}}>
                    <HStack mb={3} justifyContent={''}>
                        <Box w="100%">
                            <img width="50" src={logo} alt="logo"/>
                        </Box>

                        <Badge background={'#204ab5'} color={'white'} fontSize="1em">
                            <FormattedMessage id={'header.newBadge'}/>
                        </Badge>
                    </HStack>

                    <Heading mb={[1, 5]} fontSize={[22, 30]}>
                        <FormattedMessage id={'header.title'}/>
                    </Heading>

                    <Text fontSize={[16, 20]}>
                        <FormattedMessage id={'header.subTitle'}/>
                    </Text>
                </Box>

                <Show below={'md'}>
                    <Box w={'100%'} pt={2}>
                        <Button w={'100%'} size={'md'} background={'#204ab5'} color={'white'} onClick={scrollToBottom}
                                _hover={{bg: 'black'}}>
                            <ArrowForwardIcon mr={1}/>
                            <FormattedMessage id={'header.button.title'}/>
                        </Button>

                        <Box pt={2} textAlign={'center'}>
                            <Text as={'i'}><FormattedMessage id={'header.button.subline'}/></Text>
                        </Box>
                    </Box>
                </Show>
            </HStack>
        </Container>
    </Box>)
}
